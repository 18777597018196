<template>
  <div class="jrlsjcj drag" id="jrlsjcj" v-draw>
        <div>
    <div class="title-hang  fl"> 
     {{ projectData.project }}_数据采集
    </div>
         <div class="param11-header-icon  fr" @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
  <div class="titlelable">稳态数据采集</div>
  <div class="top">
    <div class="fl topbutton"
    :style="{background:(infoList.G_CJLL.CJ_START ?'red':'#29DFAE')}"
                 @click="toIpt(infoList.G_CJLL.CJ_START, '','CJ_START','G_CJLL')"  style="color:#fff">
                   {{infoList.G_CJLL.CJ_START ? '启动存储' :'未存储'}}
                   </div>
    <div class="fr">
      <div class="topbutton fl"
      :style="{background:(infoList.G_CJLL.CJ_MA ?'red':'#29DFAE')}"
                 @click="toIpt(infoList.G_CJLL.CJ_MA, '','CJ_MA','G_CJLL')"  style="color:#fff">
                   {{infoList.G_CJLL.CJ_MA ? '周期存储' :'触发存储'}}
                   </div>
      <div class=" fl widthdx1 hhh">周期存储周期</div>
      <div class="fl  col2-width2 mar-rig hhh" @click="
                  toIpt(
                    infoList.G_CJLL.CJ_MA_TIME,
                    '周期存储周期',
                    'CJ_MA_TIME',
                    'G_CJLL',
                    
                  )
                "
        >
          {{ infoList.G_CJLL.CJ_MA_TIME}}&nbsp;S</div>
    </div>
  </div>
  <div class="main">
    <!-- 左边 -->
  <div class="fl">
  <div class="leftmain">
    <!-- 左边第一部分左边。 -->
    <div class="fl row1-col1">
      <div class="widthd">变量稳定性判断时间</div>
      <div class="widthd">阀前煤气压力正常标志</div>
      <div class="widthd">煤气总管阀位正常标志</div>
      <div class="widthd">煤气总管流量正常标志</div>
      <div class="widthd">稳态数据不重复标志</div>
    </div>
       <!-- 左边第一部分中间边。 -->
    <div class="fl row1-col2">
    <div class="row1-col2-lab">煤气总管稳态数据采集</div>
    <div class="row1-col2-lab1" @click="
                  toIpt(
                    infoList.G_CJLL.TIME_CY_Z,
                    '变量稳定性判断时间',
                     'TIME_CY_Z',
                    'G_CJLL',
                   
                  )
                "
        >
          {{ infoList.G_CJLL.TIME_CY_Z}}&nbsp;S</div>
    <div class="row1-col2-lab3">
      <div class="fl col2-width">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.P_FQ_E,
                    '阀前煤气压力正常标志偏差',
                     'P_FQ_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.P_FQ_E}}</div>
    </div>
        <div class="row1-col2-lab31">
      <div class="fl col2-width">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_MZ_E,
                    '煤气总管阀位正常标志偏差',
                     'FW_MZ_E',
                    'G_CJLL',
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_MZ_E}}</div>
    </div>
            <div class="row1-col2-lab31">
      <div class="fl col2-width">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.Q_MZ_E,
                    '煤气总管流量正常标志偏差',
                     'Q_MZ_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.Q_MZ_E}}</div>
        <div class="fl col2-width3">有效值下限</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.Q_MZ_Q,
                    '煤气总管流量正常标志有效值下限',
                    'Q_MZ_Q',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.Q_MZ_Q}}</div>
    </div>
    <div class=" col2-row3">
      <div class="fl col2-row3-h">相同工况条件判断：</div>
      <div class="fl">
        <div >
     <div class="fl col2-width4">压力偏差</div>
      <div class="fl col2-width5" @click="
                  toIpt(
                    infoList.G_CJLL.CF_P,
                    '压力偏差',
                     'CF_P',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.CF_P}}</div>
      </div>
            <div >
     <div class="fl col2-width4">阀位偏差</div>
      <div class="fl col2-width5" @click="
                  toIpt(
                    infoList.G_CJLL.CF_FW,
                    '阀位偏差',
                    'CF_FW',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.CF_FW}}</div>
      </div>
            <div >
     <div class="fl col2-width4">流量偏差</div>
      <div class="fl col2-width5" @click="
                  toIpt(
                    infoList.G_CJLL.CF_QZ,
                    '流量偏差',
                     'CF_QZ',
                    'G_CJLL',
                   
                  )
                "
        >
          {{ infoList.G_CJLL.CF_QZ}}</div>
      </div>    
      </div>    
    </div>
    </div>
       <!-- 左边第一部分右边。 -->
    <div class="fl row1-col3">
     触发信号采集
    </div>
  </div>
    <div class="leftmain1 jg">
      <div class="row1-col2-lab mar-left">烧炉能力稳态数据采集</div>
      <div class="bottom-row1">
        <div class="fl bottom-col1 mar-left1 ">烧炉能力不足时间延迟</div>
        <div class="row1-col2-lab1 mar-left1 fl" @click="
                  toIpt(
                    infoList.G_CJLL.NL_LOW,
                    '烧炉能力不足时间延迟',
                     'NL_LOW',
                    'G_CJLL',
                   
                  )
                "
        >
          {{ infoList.G_CJLL.NL_LOW}}&nbsp;S</div>
        <div class="fl bottom-col1 mar-left2 ">烧炉能力不足采样时间</div>
        <div class="row1-col2-lab1 mar-left1 fl" @click="
                  toIpt(
                    infoList.G_CJLL.TIME_NL_CJ,
                    '烧炉能力不足采样时间',
                     'TIME_NL_CJ',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.TIME_NL_CJ}}&nbsp;S</div>
      </div>
      <div class="bottom-row1">
        <div class="fl bottom-row1-lable">煤气总管能力流量不足标志</div>
        <div class="fl line1">

        </div>
      </div>
            <div class="bottom-row1">
        <div class="fl bottom-row1-lable">均热段煤气流量不足标志</div>
        <div class="fl line1"></div>
        <div class="fl">
          <div class="bottom-row1-lab jg2xxx">触发信号采集</div>
          <div class=" line1 jg2x1"></div>
        </div>
      </div>
           <div class="bottom-row1">
        <div class="fl bottom-row1-lable">风量不足标志</div>
        <div class="fl line1">
          <div class="fl pianhcx">偏差</div>
      <div class="fl col2-width2 pianhcx1" @click="
                  toIpt(
                    infoList.G_CJLL.Q_M1_E,
                    '风量不足标志偏差',
                     'Q_M1_E',
                    'G_CJLL',
                   
                  )
                "
        >
          {{ infoList.G_CJLL.Q_M1_E}}</div>  
        </div>
      </div>

  </div>
  </div>
  <!-- 右边 -->
  <div class="fl rightmain">
 <div class="row1-col2-lab mar-leftr">支管流量稳态数据采集</div>
      <div class="bottom-row1">
        <div class="fl bottom-row1-lable ">变量稳定性判断时间</div>
        <div class="row1-col2-lab1 mar-left1 fl" @click="
                  toIpt(
                    infoList.G_CJLL.TIME_CY,
                    '变量稳定性判断时间',
                    'TIME_CY',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.TIME_CY}}&nbsp;S</div>   
      </div>
            <div class="bottom-row1">
        <div class="fl bottom-row1-lable">煤气总管压力正常标志</div>
        <div class="fl line1"></div>
           <div class="fl col2-width mar-left1">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.P_FH_E,
                    '煤气总管压力正常标志偏差',
                    'P_FH_E',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.P_FH_E}}</div>   
      </div>
      <div class="bottom-row1">
        <div class="fl bottom-row1-lable">均热段空气阀门正常标志</div>
        <div class="fl line2 jg">
           <div class="fl col2-width mar-left1">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_K1_E,
                    '均热段空气阀门正常标志偏差',
                    'FW_K1_E',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.FW_K1_E}}</div>   
      </div>
      </div>
      <div class="bottom-row11">
        <div class="fl bottom-row1-lable">均热段煤气阀门正常标志</div>
        <div class="fl line3 ">
           <div class="fl col2-width mar-left1">偏差</div>
      <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_M1_E,
                    '均热段煤气阀门正常标志偏差',
                     'FW_M1_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_M1_E}}</div>   
      </div>
   </div>
         <div class=" margile ">
       
          <!-- <div class=" line1x1 jg2x11"></div> -->
        </div>
           <div class="bottom-row1-lab fl jg2x">触发信号采集</div>
 <div class="mar-top">    
     <div class="bottom-row1">
        <div class="fl bottom-row1-lable fl">三加热段空气阀门正常标志</div>
        <div class="fl">
          <div class="jg4"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_K2_E,
                    '三加热段空气阀门正常标志偏差',
                    'FW_K2_E',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.FW_K2_E}}</div>  </div>     
             <div class=" line22 jg3"> 
                     <div class="jg5"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_M2_E,
                    '三加热段煤气阀门正常标志偏差',
                     'FW_M2_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_M2_E}}</div>  </div>    
             </div>
          </div>
              <div class="fl bottom-row1-lable jg7 ">三加热段煤气阀门正常标志</div>
              <div>
                      <div class=" line1x fl"> 
                         
       </div>
              </div>
    <div class="bottom-row1-lab fl jg2x">触发信号采集</div>
      </div>
 

    
    </div>
  
     <div class="mar-top jg9 ">    
     <div class="bottom-row1 ">
        <div class="fl bottom-row1-lable fl">二加热段空气阀门正常标志</div>
        <div class="fl">
          <div class="jg4"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_K3_E,
                    '二加热段空气阀门正常标志偏差',
                    'FW_K3_E',
                    'G_CJLL'
                    
                  )
                "
        >
          {{ infoList.G_CJLL.FW_K3_E}}</div>  </div>     
             <div class=" line22 jg3"> 
                     <div class="jg5"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_M3_E,
                    '二加热段煤气阀门正常标志偏差',
                     'FW_M3_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_M3_E}}</div>  </div>    
             </div>
          </div>
              <div class="fl bottom-row1-lable jg7 ">二加热段煤气阀门正常标志</div>
              <div>
                      <div class=" line1x fl"> 
                         <div class="bottom-row1-lab jg10">触发信号采集</div>
       </div>
              </div>
 
      </div>
 

    
    </div>
         <div class="mar-top jg9 ">    
     <div class="bottom-row1 ">
        <div class="fl bottom-row1-lable fl">一加热段空气阀门正常标志</div>
        <div class="fl">
          <div class="jg4"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_K4_E,
                    '一加热段空气阀门正常标志偏差',
                     'FW_K4_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_K4_E}}</div>  </div>     
             <div class=" line22 jg3"> 
                     <div class="jg5"> 
             <div class="fl col2-width ">偏差</div>
          <div class="fl col2-width2" @click="
                  toIpt(
                    infoList.G_CJLL.FW_M4_E,
                    '一加热段煤气阀门正常标志偏差',
                     'FW_M4_E',
                    'G_CJLL'
                   
                  )
                "
        >
          {{ infoList.G_CJLL.FW_M4_E}}</div>  </div>    
             </div>
          </div>
              <div class="fl bottom-row1-lable jg7 ">一加热段煤气阀门正常标志</div>
              <div>
                      <div class=" line1x fl"> 
                         <div class="bottom-row1-lab jg10">触发信号采集</div>
       </div>
              </div>
 
      </div>
 

    
    </div>


  </div>
  </div>
    

  <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>



 
  
     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
  name: "index",
  components: {
         Manual,
    Historical,
    inputVal,
  },
     props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },
  data: () => {
    return {
      isMshow:false,
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
                  spotArr:[],
      authInfo:[],
    };
  },
   watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
  },
  computed: {},
  mounted() {
         this.projectData = JSON.parse(localStorage.getItem("deviceType"))
     this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
     		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
  },
  methods: {
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    closeCompon() {
      this.$emit("sendStatus", "JRLParamwt", false);
    },
    isClose1() {
      this.isHshow = false;
    },
                     // 双击事件
    Cclick(num,mark,node,name){
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num:num,
        mark:mark,
        node:node,
        name:name
      }
      this.spotArr.push(spojobj)
      // 数组去重
      let deWeightThree = () => {
          let map = new Map();
          for (let item of this.spotArr) {
              if (!map.has(item.name)) {
                 this.$message.closeAll()
                  map.set(item.name, item);
                 this.$message.success('已添加');
              }else{
                this.$message.closeAll()
                this.$message.error('已添加到变量池里，切勿重复添加');
              }
          }
       
          return [...map.values()];
      }
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlsjcj {
  width: 78vw;
  height:85vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
    background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  	font-family: PingFang-SC-Regular;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }

  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width:53vw;
    font-family: MicrosoftYaHei;
    font-size: 0.8vw;;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    // margin-top:vh;
  }
.row1-col2{
  font-size: 0.9vw;
}
  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

 
  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    	font-family: MicrosoftYaHei;
	font-size: 1.5vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 6vh;
	letter-spacing: 0vh;
	color: #0ef7ff;
  padding-top:1vh;
  }

  .titlelable{
    width:78vw;
	height:6vh;
	font-family: PingFang-SC-Regular;
	font-size: 1.5vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 6vh;
	letter-spacing: 0.3vw;
	color: #d5fffe;
  text-align: center;
  margin-top:2vh;
  }
  .main{
    margin-left: 2vw;
    margin-top:7vh;
    	color: #8aeaff;
      font-size:0.9vw;
  }
        .param11-header-icon {
      width: 2vw;
      height: 2vh;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
      margin-left:2vw;
    }
  .leftmain{
    width: 38vw;;
	height:32vh;
	border: solid 1px #00e4ff20;
  }
    .leftmain1{
    width: 38vw;
	height: 32vh;
	border: solid 1px #00e4ff20;
  }
  .rightmain{
  width: 36vw;
	height: 65.2vh;
	border: solid 1px #00e4ff20;
  margin-left:1vw;
  }
  .jg{
    margin-top:3vh;
  }
  .topbutton{
    	width: 4vw;
	height: 3vh;
	background-color: #22fff7;
	border-radius: 0vh;
  line-height: 3vh;
   margin-left: 2vw;
   text-align: center;
   margin-top:3vh;
   cursor: pointer;
  }
  .row1-col1{
   width: 10vw;
   height: 12vh;
   margin-top:4vh;
   margin-left:1vw;
 
  }
  .row1-col2{
      width: 18vw;
   height: 15vh;
    margin-top:1.5vh;
    padding-left:1vw;
  }
  .row1-col2-lab{

	line-height: 3vh;
  margin-left:1vw;

	color: #d5fffe;
  font-size:0.9vw;
  }
  .row1-col2-lab1{

	line-height: 3vh;

	color: #00ffb4;
  cursor: pointer;
  }
  .widthd{
     width: 12vw;
   height: 4vh;
   	font-family: PingFang-SC-Regular;
	font-size:0.9vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 4vh;
	letter-spacing: 0vh;
	color: #8aeaff;
    text-align: left;
    // margin-left:1vw;
    // border: 1px saddlebrown solid;
  }
   .widthdx1{
     width: 7vw;
   height: 4vh;
   	font-family: PingFang-SC-Regular;
	font-size:0.9vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 4vh;
	letter-spacing: 0vh;
	color: #8aeaff;
    text-align: left;
    margin-left:1vw;
    // border: 1px saddlebrown solid;
  }
  .hhh{
    margin-top:2vh;
  }
  .row1-col2-lab3{
    border-top:1px #00e4ff solid;
    border-right: #00e4ff 1px solid;
    border-bottom: #00e4ff 1px solid;
    width:18vw;
    height: 4vh;;
    margin-top:2vh;
  }
    .row1-col2-lab31{
  
    border-right: #00e4ff 1px solid;
    border-bottom: #00e4ff 1px solid;
    width:18vw;
    height: 4vh;;
  }
  .mar-left{
    margin-left:12vw;
    margin-top:4vh;
  }
  .mar-rig{
margin-right:1vw;
width:5vw;
  }
  .mar-leftr{
    margin-left:13vw;
    margin-top:1vh;
  }
    .mar-left1{
    margin-left:1vw;
  }
      .mar-left2{
    margin-left:3vw;
  }
  .pianhcx{
    margin-top:-4vh;
    width:5vw;
    // border: #00e4ff 1px solid;
  }
  .pianhcx1{
      margin-top:-4vh;
       margin-left:3vw;
  }
  .col2-width{
    width:3vw;
    color: #00e4ff;
     font-size: 0.9vw;
  }
  .line1{
    width: 9vw;
     border-bottom: #00e4ff 1px solid;
     margin-top:1.5vh;
     margin-left:1vw;
  }
    .line1x1{
    width: 8vw;
     border-bottom: #00e4ff 1px solid;
     margin-top:1.5vh;
     margin-left:1vw;
  }
    .line11{
    width: 5vw;
     border: #00e4ff 1px solid;
     margin-top:1.5vh;
     margin-left:1vw;
  }

    .line1x{
    width: 20vw;
     border-bottom: #00e4ff 1px solid;
    //  margin-top:-1.5vh;
     margin-left:1vw;
  }
  .jg{
    margin-top:1vh;
  }
  .line2{
    width: 12vw;
     border-top: #00e4ff 1px solid;
     border-right: #00e4ff 1px solid;
    //  border-bottom: #00e4ff 1px solid;
     margin-left:1vw;
  }
    .line22{
    width: 12vw;
    height: 5vh;
     border-right: #00e4ff 1px solid;
     border-top: #00e4ff 1px solid;
     margin-left:1vw;
  }
  .jg3{
    margin-top:4vh;
  }
    .jg7{
    margin-top:-1vh;
  }
  .jg4{
    margin-top:-3vh;
    margin-left:2vw;
  }
    .jg5{
    margin-top:1.2vh;
    margin-left:1vw;
  }
      .jg6{
   
    margin-top:-4vh;
     margin-left:1vw;
  }
        .jg8{
   
    margin-top:4vh;
  }
    .line3{
    width: 12vw;
     border-top: #00e4ff 1px solid;
     border-right: #00e4ff 1px solid;
    // border-bottom: #00e4ff 1px solid;
     margin-left:1vw;
     height: 3vh;
  }
      .line33{
    width: 12vw;
     border-top: #00e4ff 1px solid;
     border-right: #00e4ff 1px solid;
    border-bottom: #00e4ff 1px solid;
     margin-left:1vw;
  }

  .bottom-row1{
    padding-left:1vw;
    padding-top:1vh;
    width:35vw; 
    height: 3vh; 
  }
  
  .bottom-row11{
    padding-left:1vw;
    width:35vw; 
    height: 4vh; 
  }
  .bottom-row1-lable{
    width:13vw;
    text-align: right;
    line-height: 2vh;
  }

    .bottom-row1-lable11{
    width:11vw;
    text-align: right;
    height: 3vh;
  }
  .mar-top{
    margin-top:6vh;
  }
   .margile{
margin-left:15vw;

border-top:1px solid #00e4ff;
width:20vw;
margin-top:0.48vh;
// height: 2vh;
   }
  .bottom-row1-lab{
    height: 1.5vh;
    margin-top:-1.5vh;
    margin-left:2vw;
  }
  .zhanwei{
    width:10vw;
  }
  .jg2x1{
    margin-top:3vh;
  }
    .jg2x11{
    margin-top:3.5vh;
    margin-left:-0.1vw;
  }
    .col2-width2{
    width:4vw;
    color: #00ffb4;
     font-size: 0.9vw;
     cursor: pointer;
  }
      .col2-width5{
    width:4vw;
    color: #00ffb4;
    line-height: 2.5vh;
      font-size: 0.9vw;
      cursor: pointer;
  }
   .col2-width3{
    width:6vw;
    color: #00e4ff;
      font-size: 0.9vw;
   
  }
     .col2-width4{
    width:5vw;
    line-height:2.5vh;
      font-size: 0.9vw;
 
  }
  .col2-row3{
       height:6vh;    
          font-size:0.9vw;
          margin-top:1vh;
          width:20vw;
  }
  .jg2x{
    margin-top:-4.2vh;
    margin-left: 28vw;
  }
    .jg2xxx{
    margin-left: 1vw;
     margin-top:-3vh;
  }
  .col2-row3-h{
     line-height: 3vh;
  }
  .jg10{
    margin-left:13vw;
    margin-top:-2vw;
  }
  .jg9{
    margin-top:10vh;
  }
.row1-col3{
  border-bottom:#00e4ff 1px solid;
  height: 6vh;
  width:7vw;
  margin-top:15.8vh;
   font-size: 0.9vw;;
  margin-left:0vw;
  padding-left:1vw;
}


  
  
}
</style>