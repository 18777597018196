<template>
    <div class="jrl_kqzg drag" v-draw id="Jrlme">
           <div class="title-hang" fl> 
     {{ projectData.project }}_软测量1
    </div>
         <div class="param11-header-icon"  fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
            <div class="param02-tab">
    <div class="param11-tab-tit">各加热段煤气空气软测量模型</div>
    <div class="tab-list">
        <div class="row row1 flex align-cen">
            <div class="row-first">类别</div>
            <div>实际测量</div>
            <div>软测量值</div>
            <div>阀门开度</div>
            <div>阀门压差</div>
            <div>阀门管径</div>
            <div>介质密度</div>
            <div>修正系数</div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">均热段空气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_101_B', 'G_SOUREC', 'FICA_101_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_101_B,'FICA_101_B', 'G_SOUREC', 'FICA_101_J1')"
            >{{infoList.G_SOUREC.FICA_101_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL11', 'RCL11_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL11.Q,'Q', 'G_RCLMQZG__p__RCL11', 'RCL11_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL11.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL11', 'RCL11_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL11.FV,'FV', 'G_RCLMQZG__p__RCL11', 'RCL11_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL11.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL11', 'RCL11_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL11.P,'P', 'G_RCLMQZG__p__RCL11', 'RCL11_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL11.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL11.DN, '均热段空气阀门管径', 'DN', 'G_RCLMQZG__p__RCL11')"
            >{{infoList.G_RCLMQZG__p__RCL11.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL11.ROU, '均热段空气介质密度', 'ROU', 'G_RCLMQZG__p__RCL11')"
            >{{infoList.G_RCLMQZG__p__RCL11.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL11', 'RCL11_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL11.K,'K', 'G_RCLMQZG__p__RCL11', 'RCL11_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL11.K || '0.00'}}
            </div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">均热段煤气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_102_B', 'G_SOUREC', 'FICA_102_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_102_B,'FICA_102_B', 'G_SOUREC', 'FICA_102_J1')"
            >{{infoList.G_SOUREC.FICA_102_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL12', 'RCL12_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL12.Q,'Q', 'G_RCLMQZG__p__RCL12', 'RCL12_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL12.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL12', 'RCL12_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL12.FV,'FV', 'G_RCLMQZG__p__RCL12', 'RCL12_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL12.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL12', 'RCL12_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL12.P,'P', 'G_RCLMQZG__p__RCL12', 'RCL12_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL12.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL12.DN, '均热段煤气阀门管径', 'DN', 'G_RCLMQZG__p__RCL12')"
            >{{infoList.G_RCLMQZG__p__RCL12.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL12.ROU, '均热段煤气介质密度', 'ROU', 'G_RCLMQZG__p__RCL12')"
            >{{infoList.G_RCLMQZG__p__RCL12.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL12', 'RCL12_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL12.K,'K', 'G_RCLMQZG__p__RCL12', 'RCL12_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL12.K || '0.00'}}
            </div>
        </div>
          <div class="row  flex align-cen">
            <div class="row-first">三加热空气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_103_B', 'G_SOUREC', 'FICA_103_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_103_B,'FICA_103_B', 'G_SOUREC', 'FICA_103_J1')"
            >{{infoList.G_SOUREC.FICA_103_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL21', 'RCL21_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL21.Q,'Q', 'G_RCLMQZG__p__RCL21', 'RCL21_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL21.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL21', 'RCL21_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL21.FV,'FV', 'G_RCLMQZG__p__RCL21', 'RCL21_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL21.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL21', 'RCL21_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL21.P,'P', 'G_RCLMQZG__p__RCL21', 'RCL21_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL21.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL21.DN, '三加热空气阀门管径', 'DN', 'G_RCLMQZG__p__RCL21')"
            >{{infoList.G_RCLMQZG__p__RCL21.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL21.ROU, '三加热空气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL21')"
            >{{infoList.G_RCLMQZG__p__RCL21.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL21', 'RCL21_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL21.K,'K', 'G_RCLMQZG__p__RCL21', 'RCL21_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL21.K || '0.00'}}
            </div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">三加热煤气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_104_B', 'G_SOUREC', 'FICA_104_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_104_B,'FICA_104_B', 'G_SOUREC', 'FICA_104_J1')"
            >{{infoList.G_SOUREC.FICA_104_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL22', 'RCL22_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL22.Q,'Q', 'G_RCLMQZG__p__RCL22', 'RCL22_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL22.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL22', 'RCL22_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL22.FV,'FV', 'G_RCLMQZG__p__RCL22', 'RCL22_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL22.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL22', 'RCL22_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL22.P,'P', 'G_RCLMQZG__p__RCL22', 'RCL22_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL22.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL22.DN, '三加热煤气阀门管径', 'DN', 'G_RCLMQZG__p__RCL22')"
            >{{infoList.G_RCLMQZG__p__RCL22.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL22.ROU, '三加热煤气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL22')"
            >{{infoList.G_RCLMQZG__p__RCL22.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL22', 'RCL22_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL22.K,'K', 'G_RCLMQZG__p__RCL22', 'RCL22_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL22.K || '0.00'}}
            </div>
        </div>
        <div class="row  flex align-cen">
            <div class="row-first">二加热空气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_105_B', 'G_SOUREC', 'FICA_105_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_105_B,'FICA_105_B', 'G_SOUREC', 'FICA_105_J1')"
            >{{infoList.G_SOUREC.FICA_105_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL31', 'RCL31_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL31.Q,'Q', 'G_RCLMQZG__p__RCL31', 'RCL31_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL31.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL31', 'RCL31_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL31.FV,'FV', 'G_RCLMQZG__p__RCL31', 'RCL31_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL31.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL31', 'RCL31_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL31.P,'P', 'G_RCLMQZG__p__RCL31', 'RCL31_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL31.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL31.DN, '二加热空气阀门管径', 'DN', 'G_RCLMQZG__p__RCL31')"
            >{{infoList.G_RCLMQZG__p__RCL31.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL31.ROU, '二加热空气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL31')"
            >{{infoList.G_RCLMQZG__p__RCL31.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL31', 'RCL31_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL31.K,'K', 'G_RCLMQZG__p__RCL31', 'RCL31_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL31.K || '0.00'}}
            </div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">二加热煤气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_106_B', 'G_SOUREC', 'FICA_106_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_106_B,'FICA_106_B', 'G_SOUREC', 'FICA_106_J1')"
            >{{infoList.G_SOUREC.FICA_106_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL32', 'RCL32_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL32.Q,'Q', 'G_RCLMQZG__p__RCL32', 'RCL32_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL32.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL32', 'RCL32_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL32.FV,'FV', 'G_RCLMQZG__p__RCL32', 'RCL32_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL32.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL32', 'RCL32_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL32.P,'P', 'G_RCLMQZG__p__RCL32', 'RCL32_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL32.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL32.DN, '二加热煤气阀门管径', 'DN', 'G_RCLMQZG__p__RCL32')"
            >{{infoList.G_RCLMQZG__p__RCL31.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL32.ROU, '二加热煤气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL32')"
            >{{infoList.G_RCLMQZG__p__RCL32.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL32', 'RCL32_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL32.K,'K', 'G_RCLMQZG__p__RCL32', 'RCL32_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL32.K || '0.00'}}
            </div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">一加热空气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_107_B', 'G_SOUREC', 'FICA_107_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_107_B,'FICA_107_B', 'G_SOUREC', 'FICA_107_J1')"
            >{{infoList.G_SOUREC.FICA_107_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL41', 'RCL41_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL41.Q,'Q', 'G_RCLMQZG__p__RCL41', 'RCL41_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL41.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL41', 'RCL41_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL41.FV,'FV', 'G_RCLMQZG__p__RCL41', 'RCL41_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL41.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL41', 'RCL41_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL41.P,'P', 'G_RCLMQZG__p__RCL41', 'RCL41_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL41.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL41.DN, '一加热空气阀门管径', 'DN', 'G_RCLMQZG__p__RCL41')"
            >{{infoList.G_RCLMQZG__p__RCL41.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL41.ROU, '一加热空气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL41')"
            >{{infoList.G_RCLMQZG__p__RCL41.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL41', 'RCL41_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL41.K,'K', 'G_RCLMQZG__p__RCL41', 'RCL41_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL41.K || '0.00'}}
            </div>
        </div>
         <div class="row  flex align-cen">
            <div class="row-first">一加热煤气</div>
            <div
            class="his"
            @click="toCompon(2, 'FICA_108_B', 'G_SOUREC', 'FICA_108_J1')"
            @dblclick="Cclick(infoList.G_SOUREC.FICA_108_B,'FICA_108_B', 'G_SOUREC', 'FICA_108_J1')"
            >{{infoList.G_SOUREC.FICA_108_B}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'Q', 'G_RCLMQZG__p__RCL42', 'RCL42_Q_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL42.Q,'Q', 'G_RCLMQZG__p__RCL42', 'RCL42_Q_J1')"
            >{{infoList.G_RCLMQZG__p__RCL42.Q}}
            </div>
            <div
            class="his"
            @click="toCompon(2, 'FV', 'G_RCLMQZG__p__RCL42', 'RCL42_FV_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL42.FV,'FV', 'G_RCLMQZG__p__RCL42', 'RCL42_FV_J1')"
            >{{infoList.G_RCLMQZG__p__RCL42.FV}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'P', 'G_RCLMQZG__p__RCL42', 'RCL42_P_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL42.P,'P', 'G_RCLMQZG__p__RCL42', 'RCL42_P_J1')"
            >{{infoList.G_RCLMQZG__p__RCL42.P}}
            </div>
             <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL42.DN, '一加热煤气阀门管径', 'DN', 'G_RCLMQZG__p__RCL42')"
            >{{infoList.G_RCLMQZG__p__RCL42.DN}}
            </div>
            <div
            class="ipt"
            @click="toIpt(infoList.G_RCLMQZG__p__RCL42.ROU, '一加热煤气气介质密度', 'ROU', 'G_RCLMQZG__p__RCL42')"
            >{{infoList.G_RCLMQZG__p__RCL42.ROU}}
            </div>
             <div
            class="his"
            @click="toCompon(2, 'K', 'G_RCLMQZG__p__RCL42', 'RCL42_K_J1')"
            @dblclick="Cclick(infoList.G_RCLMQZG__p__RCL42.K,'K', 'G_RCLMQZG__p__RCL42', 'RCL42_K_J1')"
            >{{infoList.G_RCLMQZG__p__RCL42.K || '0.00'}}
            </div>
        </div>
    </div>
    </div>
     <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
     <Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
  </div>
</template>
<script>

import inputVal from "./inputVal"   //输入框组件
import Historical from "@/components/Historical.vue"  //历史趋势
export default {
    name: "Param11",
     props:{
        infoList:{
            type:Object,
            default: {} // 默认值
        }
    },
 components:{inputVal, Historical},
    data: () => {
		return {
       Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
         isComShow:false,
      isHshow:false,
      isMshow:false,
      isRshow:false,
      historyname:'',
      node:'',
      Lkname:'',
      isIndex:'',
      Firstcontrolname:'',
      Firstcontroltitname:'',
      Firstcontrolnode:'',
      isFshow:false,
      Rsfname:'',
      Rsfnode:'',
      Rsftitname:'',
      projectData:'',
      grouptime:null,
      spotArr:[],
      authInfo:[],
      UserInfo:[],
		}
        
	},
  created(){
     this.projectData = JSON.parse(localStorage.getItem("deviceType"))
     this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
     		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
  },
  methods:{
         // 获取自组件传过来的值
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    		// 打开先控多窗口
		toDetail(key,name,nodename,Aname,titname){
			if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
				let pathname=""
				switch(key){
					case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'kfirstcontol'
					break
				}
				const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
				window.open(newurl.href,'_blank')
			}
		},
         // 双击事件
    Cclick(num,mark,node,name){
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num:num,
        mark:mark,
        node:node,
        name:name
      }
      this.spotArr.push(spojobj)
      // 数组去重
      let deWeightThree = () => {
          let map = new Map();
          for (let item of this.spotArr) {
              if (!map.has(item.name)) {
                 this.$message.closeAll()
                  map.set(item.name, item);
                 this.$message.success('已添加');
              }else{
                this.$message.closeAll()
                this.$message.error('已添加到变量池里，切勿重复添加');
              }
          }
       
          return [...map.values()];
      }
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
    },
       isshowfase(){
       this.isComShow = false
     },
    closeCompon(){

      this.$emit('sendStatus', 'JRL_Measurement',false)
    },
    isClose(val,val1){
      switch(val){
        case 'Historical':
          return this.isHshow = val1
      }
    },
    toIpt(data,name,historyname,node){

      this.$refs.inputVal.open(data,name,historyname,node)
    },
    toCompon(key,name,name2,name3,titname){
			this.isIndex = key
			this.historyname = name
			this.node = name2
			this.Lkname = name3
      clearTimeout(this.grouptime);
      this.grouptime=setTimeout(() =>{
        		switch(key){
				  case 0:
          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
        case 1 :
          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
        case 2:
          return  this.isHshow = true
        case 4:
				return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
			}
      },300);    
		},
    } 
}
</script>

<style lang="scss" scoped>
 .jrl_kqzg{
     width: 53vw;
     height: 67vh;
   background: url("~@/assets/images/jrl-param.png") no-repeat 0 0;
     background-size: 100% 100%;
     cursor:move;
     border-radius: 5px;
     .fl{
         float: left;
     }
     .fr{
         float: right;
     }
       .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    	font-family: MicrosoftYaHei;
	font-size: 3vh;
	font-weight: normal;
	font-stretch: normal;
	line-height: 6vh;
	letter-spacing: 0vh;
	color: #0ef7ff;
  }
     .param11-header-icon {
      width: 2vw;
      height: 2vh;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
      margin-left:50vw;
    }
  .param11-header {
    height: 50px;
    //  background: #5DCEF3;
    // line-height: 50px;
    text-align: center;
    color: #041a28;
    font-size: 1.5vw;
    position: relative;
    border-radius: 5px 5px 0 0;
    .param11-header-title {
      width: 179px;
      height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #0ef7ff;
    }
    .param11-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
    }
  }
      .param02-tab{
                
         .param11-tab-tit{
             margin-top:3vh;
             width: 100%;
             height: 26px;
             font-family: PingFang-SC-Regular;
             line-height: 20px;
             font-weight: normal;
             font-stretch: normal;
             font-size: 1.2vw;
             text-align: center;
             letter-spacing: 6px;
             color: #d5fffe;
         }
        .tab-list{
            width: 50vw;
            height: 54vh;
            margin: 0 auto;
             box-sizing: border-box;
            border-top: 1px solid rgba(0,228,255,.2);
            border-left:1px solid rgba(0,228,255,.2);
            margin-top: 2vh;
            .row{
                width: 50vw;
                height: 6vh;
                line-height: 6vh;
                text-align: center;
                color: #8aeaff;
                
                font-size: 1vw;
                div{
                    width: 6vw;
                    height: 6vh;
                box-sizing: border-box;
                 border-bottom: 1px solid rgba(0,228,255,.2);
            border-right:1px solid rgba(0,228,255,.2);
                }
                .his{
                    cursor: pointer;
                    color: #2fc3e3;
                }
                .ipt{
                     cursor: pointer;
                    color: #00ffb4;
                }
                .row-first{
                    width: 8vw;
                }
            }
            .row1{background: #003f5b;color: #2fc3e3;}
        }
      }
         }
</style>
 