var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrlsjcj drag",attrs:{"id":"jrlsjcj"}},[_c('div',[_c('div',{staticClass:"title-hang  fl"},[_vm._v(" "+_vm._s(_vm.projectData.project)+"_数据采集 ")]),_c('div',{staticClass:"param11-header-icon  fr",on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("稳态数据采集")]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"fl topbutton",staticStyle:{"color":"#fff"},style:({background:(_vm.infoList.G_CJLL.CJ_START ?'red':'#29DFAE')}),on:{"click":function($event){return _vm.toIpt(_vm.infoList.G_CJLL.CJ_START, '','CJ_START','G_CJLL')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CJ_START ? '启动存储' :'未存储')+" ")]),_c('div',{staticClass:"fr"},[_c('div',{staticClass:"topbutton fl",staticStyle:{"color":"#fff"},style:({background:(_vm.infoList.G_CJLL.CJ_MA ?'red':'#29DFAE')}),on:{"click":function($event){return _vm.toIpt(_vm.infoList.G_CJLL.CJ_MA, '','CJ_MA','G_CJLL')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CJ_MA ? '周期存储' :'触发存储')+" ")]),_c('div',{staticClass:" fl widthdx1 hhh"},[_vm._v("周期存储周期")]),_c('div',{staticClass:"fl  col2-width2 mar-rig hhh",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.CJ_MA_TIME,
                   '周期存储周期',
                   'CJ_MA_TIME',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CJ_MA_TIME)+" S")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"leftmain"},[_vm._m(0),_c('div',{staticClass:"fl row1-col2"},[_c('div',{staticClass:"row1-col2-lab"},[_vm._v("煤气总管稳态数据采集")]),_c('div',{staticClass:"row1-col2-lab1",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.TIME_CY_Z,
                   '变量稳定性判断时间',
                    'TIME_CY_Z',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.TIME_CY_Z)+" S")]),_c('div',{staticClass:"row1-col2-lab3"},[_c('div',{staticClass:"fl col2-width"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.P_FQ_E,
                   '阀前煤气压力正常标志偏差',
                    'P_FQ_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.P_FQ_E))])]),_c('div',{staticClass:"row1-col2-lab31"},[_c('div',{staticClass:"fl col2-width"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_MZ_E,
                   '煤气总管阀位正常标志偏差',
                    'FW_MZ_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_MZ_E))])]),_c('div',{staticClass:"row1-col2-lab31"},[_c('div',{staticClass:"fl col2-width"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.Q_MZ_E,
                   '煤气总管流量正常标志偏差',
                    'Q_MZ_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.Q_MZ_E))]),_c('div',{staticClass:"fl col2-width3"},[_vm._v("有效值下限")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.Q_MZ_Q,
                   '煤气总管流量正常标志有效值下限',
                   'Q_MZ_Q',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.Q_MZ_Q))])]),_c('div',{staticClass:" col2-row3"},[_c('div',{staticClass:"fl col2-row3-h"},[_vm._v("相同工况条件判断：")]),_c('div',{staticClass:"fl"},[_c('div',[_c('div',{staticClass:"fl col2-width4"},[_vm._v("压力偏差")]),_c('div',{staticClass:"fl col2-width5",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.CF_P,
                   '压力偏差',
                    'CF_P',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CF_P))])]),_c('div',[_c('div',{staticClass:"fl col2-width4"},[_vm._v("阀位偏差")]),_c('div',{staticClass:"fl col2-width5",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.CF_FW,
                   '阀位偏差',
                   'CF_FW',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CF_FW))])]),_c('div',[_c('div',{staticClass:"fl col2-width4"},[_vm._v("流量偏差")]),_c('div',{staticClass:"fl col2-width5",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.CF_QZ,
                   '流量偏差',
                    'CF_QZ',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.CF_QZ))])])])])]),_c('div',{staticClass:"fl row1-col3"},[_vm._v(" 触发信号采集 ")])]),_c('div',{staticClass:"leftmain1 jg"},[_c('div',{staticClass:"row1-col2-lab mar-left"},[_vm._v("烧炉能力稳态数据采集")]),_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-col1 mar-left1 "},[_vm._v("烧炉能力不足时间延迟")]),_c('div',{staticClass:"row1-col2-lab1 mar-left1 fl",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.NL_LOW,
                   '烧炉能力不足时间延迟',
                    'NL_LOW',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.NL_LOW)+" S")]),_c('div',{staticClass:"fl bottom-col1 mar-left2 "},[_vm._v("烧炉能力不足采样时间")]),_c('div',{staticClass:"row1-col2-lab1 mar-left1 fl",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.TIME_NL_CJ,
                   '烧炉能力不足采样时间',
                    'TIME_NL_CJ',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.TIME_NL_CJ)+" S")])]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("风量不足标志")]),_c('div',{staticClass:"fl line1"},[_c('div',{staticClass:"fl pianhcx"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2 pianhcx1",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.Q_M1_E,
                   '风量不足标志偏差',
                    'Q_M1_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.Q_M1_E))])])])])]),_c('div',{staticClass:"fl rightmain"},[_c('div',{staticClass:"row1-col2-lab mar-leftr"},[_vm._v("支管流量稳态数据采集")]),_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable "},[_vm._v("变量稳定性判断时间")]),_c('div',{staticClass:"row1-col2-lab1 mar-left1 fl",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.TIME_CY,
                   '变量稳定性判断时间',
                   'TIME_CY',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.TIME_CY)+" S")])]),_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("煤气总管压力正常标志")]),_c('div',{staticClass:"fl line1"}),_c('div',{staticClass:"fl col2-width mar-left1"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.P_FH_E,
                   '煤气总管压力正常标志偏差',
                   'P_FH_E',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.P_FH_E))])]),_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("均热段空气阀门正常标志")]),_c('div',{staticClass:"fl line2 jg"},[_c('div',{staticClass:"fl col2-width mar-left1"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_K1_E,
                   '均热段空气阀门正常标志偏差',
                   'FW_K1_E',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_K1_E))])])]),_c('div',{staticClass:"bottom-row11"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("均热段煤气阀门正常标志")]),_c('div',{staticClass:"fl line3 "},[_c('div',{staticClass:"fl col2-width mar-left1"},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_M1_E,
                   '均热段煤气阀门正常标志偏差',
                    'FW_M1_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_M1_E))])])]),_c('div',{staticClass:" margile "}),_c('div',{staticClass:"bottom-row1-lab fl jg2x"},[_vm._v("触发信号采集")]),_c('div',{staticClass:"mar-top"},[_c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable fl"},[_vm._v("三加热段空气阀门正常标志")]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"jg4"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_K2_E,
                   '三加热段空气阀门正常标志偏差',
                   'FW_K2_E',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_K2_E))])]),_c('div',{staticClass:" line22 jg3"},[_c('div',{staticClass:"jg5"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_M2_E,
                   '三加热段煤气阀门正常标志偏差',
                    'FW_M2_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_M2_E))])])])]),_c('div',{staticClass:"fl bottom-row1-lable jg7 "},[_vm._v("三加热段煤气阀门正常标志")]),_vm._m(3),_c('div',{staticClass:"bottom-row1-lab fl jg2x"},[_vm._v("触发信号采集")])])]),_c('div',{staticClass:"mar-top jg9 "},[_c('div',{staticClass:"bottom-row1 "},[_c('div',{staticClass:"fl bottom-row1-lable fl"},[_vm._v("二加热段空气阀门正常标志")]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"jg4"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_K3_E,
                   '二加热段空气阀门正常标志偏差',
                   'FW_K3_E',
                   'G_CJLL'
                   
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_K3_E))])]),_c('div',{staticClass:" line22 jg3"},[_c('div',{staticClass:"jg5"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_M3_E,
                   '二加热段煤气阀门正常标志偏差',
                    'FW_M3_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_M3_E))])])])]),_c('div',{staticClass:"fl bottom-row1-lable jg7 "},[_vm._v("二加热段煤气阀门正常标志")]),_vm._m(4)])]),_c('div',{staticClass:"mar-top jg9 "},[_c('div',{staticClass:"bottom-row1 "},[_c('div',{staticClass:"fl bottom-row1-lable fl"},[_vm._v("一加热段空气阀门正常标志")]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"jg4"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_K4_E,
                   '一加热段空气阀门正常标志偏差',
                    'FW_K4_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_K4_E))])]),_c('div',{staticClass:" line22 jg3"},[_c('div',{staticClass:"jg5"},[_c('div',{staticClass:"fl col2-width "},[_vm._v("偏差")]),_c('div',{staticClass:"fl col2-width2",on:{"click":function($event){return _vm.toIpt(
                   _vm.infoList.G_CJLL.FW_M4_E,
                   '一加热段煤气阀门正常标志偏差',
                    'FW_M4_E',
                   'G_CJLL'
                  
                 )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_CJLL.FW_M4_E))])])])]),_c('div',{staticClass:"fl bottom-row1-lable jg7 "},[_vm._v("一加热段煤气阀门正常标志")]),_vm._m(5)])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl row1-col1"},[_c('div',{staticClass:"widthd"},[_vm._v("变量稳定性判断时间")]),_c('div',{staticClass:"widthd"},[_vm._v("阀前煤气压力正常标志")]),_c('div',{staticClass:"widthd"},[_vm._v("煤气总管阀位正常标志")]),_c('div',{staticClass:"widthd"},[_vm._v("煤气总管流量正常标志")]),_c('div',{staticClass:"widthd"},[_vm._v("稳态数据不重复标志")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("煤气总管能力流量不足标志")]),_c('div',{staticClass:"fl line1"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-row1"},[_c('div',{staticClass:"fl bottom-row1-lable"},[_vm._v("均热段煤气流量不足标志")]),_c('div',{staticClass:"fl line1"}),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottom-row1-lab jg2xxx"},[_vm._v("触发信号采集")]),_c('div',{staticClass:" line1 jg2x1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:" line1x fl"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:" line1x fl"},[_c('div',{staticClass:"bottom-row1-lab jg10"},[_vm._v("触发信号采集")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:" line1x fl"},[_c('div',{staticClass:"bottom-row1-lab jg10"},[_vm._v("触发信号采集")])])])}]

export { render, staticRenderFns }